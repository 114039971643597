// TODO: Add a Prefix like: MY_APP::STORAGE_KEY_NAME
export enum StorageKeys {
  TOKEN = 'JWT_TOKEN',
  MAGIC_LINK_EMAIL = 'MAGIC_LINK_EMAIL',
  COOKIES_PERMISSIONS = 'COOKIES_PERMISSIONS',
  USER_ID = 'USER_ID',
  USER_EMAIL = 'USER_EMAIL',
  THEME = 'THEME',
  CART_ITEMS = 'CART_ITEMS',
}
