import React, { FC, SVGProps } from 'react';

export const SOLIcon: FC<SVGProps<SVGSVGElement>> = ({
  width = 32,
  height = 32,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 84 84"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="22.0518"
        x2="61.5149"
        y1="64.0248"
        y2="19.6111"
      >
        <stop offset=".08" stopColor="#9945ff" />
        <stop offset=".3" stopColor="#8752f3" />
        <stop offset=".5" stopColor="#5497d5" />
        <stop offset=".6" stopColor="#43b4ca" />
        <stop offset=".72" stopColor="#28e0b9" />
        <stop offset=".97" stopColor="#19fb9b" />
      </linearGradient>
      <circle cx="42" cy="42" fill="#000" r="42" />
      <path
        d="m65.7527 53.9024-7.9237 8.512c-.1723.1849-.3807.3323-.6123.4331-.2316.1007-.4814.1526-.7339.1525h-37.5629c-.1792 0-.3546-.0525-.5044-.1509-.1499-.0984-.2678-.2386-.3393-.4033-.0714-.1646-.0933-.3466-.0629-.5235s.1118-.3411.234-.4723l7.9299-8.512c.1717-.1844.3795-.3315.6103-.4323.2309-.1007.4799-.1529.7317-.1533h37.5609c.1792 0 .3546.0524.5044.1509.1499.0984.2678.2386.3393.4032.0714.1647.0933.3466.0629.5236-.0304.1769-.1118.3411-.234.4723zm-7.9237-17.1406c-.1723-.1849-.3807-.3323-.6123-.4331-.2316-.1007-.4814-.1526-.7339-.1525h-37.5629c-.1792 0-.3546.0524-.5044.1509-.1499.0984-.2678.2386-.3393.4032-.0714.1647-.0933.3467-.0629.5236s.1118.3411.234.4723l7.9299 8.512c.1717.1844.3795.3315.6103.4323.2309.1007.4799.1529.7317.1533h37.5609c.1792 0 .3546-.0524.5044-.1509.1499-.0984.2678-.2386.3393-.4032.0714-.1647.0933-.3467.0629-.5236s-.1118-.3411-.234-.4723zm-38.9091-6.1142h37.5629c.2525.0002.5023-.0518.7339-.1525.2316-.1008.44-.2482.6123-.4331l7.9237-8.512c.1222-.1312.2036-.2954.234-.4723s.0085-.3589-.0629-.5235c-.0715-.1647-.1894-.3049-.3393-.4033-.1498-.0984-.3252-.1509-.5044-.1509h-37.5609c-.2518.0004-.5008.0526-.7317.1534-.2308.1007-.4386.2478-.6103.4322l-7.9278 8.512c-.1222.1311-.2035.2951-.2339.4718-.0305.1768-.0088.3586.0624.5231.0712.1646.1888.3048.3383.4034.1496.0986.3246.1513.5037.1517z"
        fill="url(#a)"
      />
    </svg>
  );
};
